import React, { useEffect, useState } from 'react';
import styles from './ImportModal.module.scss';
import { FormProvider, useForm } from "react-hook-form";
import { FormGroup, FormGroupData } from '../FormGroup/FormGroup';
import { FormField, FormFieldData } from '../FormField/FormField';
import '../../styles/tabs.scss';
import { useAuth } from '../../services/AuthContext';
import { useAppContext } from '../../services/AppContext';
import axios from 'axios';

type MyProps = {
  onSuccess: Function,
  onFailure: Function,
  type: any,
};

export function ImportModal (props: MyProps) {
  const [items, setItems] = useState<{[key: string]: any}[]>([]);

  const appState = useAppContext();
  const methods = useForm();
  const auth = useAuth();

  const onSubmit = (event: any) => {
    console.log(event);

    
  }

  const closeModal = () => {
    if(document.querySelector('.fixedLineItem'))
      {
        document.querySelector('.fixedLineItem')?.remove();
      }
    
   
    appState.hideModal();
  }

  const startImport = () => {

    const WPAPI = axios.create({
      baseURL: 'https://dev.linkstool.ca'
    });
    WPAPI.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt');
    const formData = new FormData();
    let csvFile = document.getElementsByName('import_file')[0] as HTMLInputElement;
    if(csvFile)
    {
      
      formData.append('csv',csvFile.files![0]);
       WPAPI.post('/import_data/' + props.type, formData ).then(response => {
        appState.hideModal();
        auth.getItems(props.type).then((response: any) => {
          setItems(response.data);
        });
      });
    }
  }

  useEffect(() => {
   
  }, [props])

  return (
    <div >
              <h3 className={styles.compareHeading}>Import {props.type}</h3>

      <div className={[styles.ImportModalContentWrapper,' '].join(' ')} id="">

      <input type='file' name="import_file"/>
      <input type='hidden' name="import_type" value={props.type}/>

      </div>
       

      <div className={[styles.submit].join(' ')} onClick={startImport}>Import</div>

        <div className={[styles.cancel].join(' ')} onClick={closeModal}>Close</div>
      </div>
  );

}
export type TabData = Array<{
  name: string;
  form: Array<FormFieldData|FormGroupData>
}>;