import React, { useEffect, useState } from 'react';
import styles from './CompareModal.module.scss';
import { FormProvider, useForm } from "react-hook-form";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { FormGroup, FormGroupData } from '../FormGroup/FormGroup';
import { FormField, FormFieldData } from '../FormField/FormField';
import '../../styles/tabs.scss';
import { useAuth } from '../../services/AuthContext';
import { useAppContext } from '../../services/AppContext';

type MyProps = {
  onSuccess: Function,
  onFailure: Function,
  compare: any[],
  headings: any[],
};

export function CompareModal (props: MyProps) {
  const [tabData, setTabData] = useState<TabData>([]);
  const appState = useAppContext();
  const methods = useForm();
  const auth = useAuth();

  const onSubmit = (event: any) => {
    console.log(event);

    
  }

  const closeModal = () => {
    if(document.querySelector('.fixedLineItem'))
      {
        document.querySelector('.fixedLineItem')?.remove();
      }
    setTimeout(() => {
      let modalBox = document.getElementById("comparelistwrapperstatic")!.parentNode!.parentNode!.parentNode;
     
      if(modalBox)
    {
      let modalBoxDiv = modalBox as HTMLDivElement;
      modalBoxDiv.classList.remove('comparemodalbox');
    }
    }, 1001);
   
    appState.hideModal();
  }

  useEffect(() => {
    setTabData([]);
   
  }, [props])

  return (
    <div >
              <h3 className={styles.compareHeading}>Compare</h3>

      <div className={[styles.CompareListWrapper,' '].join(' ')} id="comparelistwrapperstatic">
          {
           (() => {
setTimeout(() => {
let modalBox = document.getElementById("comparelistwrapperstatic")!.parentNode!.parentNode!.parentNode;
if(modalBox)
  {
    let modalBoxDiv = modalBox as HTMLDivElement;
    modalBoxDiv.classList.add('comparemodalbox');
    if(!document.querySelector('.fixedLineItem'))
    {
      let fixedDiv = document.createElement("div");
      fixedDiv.classList.add('fixedLineItem');
      if(document.querySelector('li[data-label="LANDING COST"]'))
      {
        let landingCostHeading = document.querySelector('li[data-label="LANDING COST"]')?.cloneNode(true);

        var child = document.querySelector('li[data-label="LANDING COST"]');
        var parent = child!.parentNode;
        // The equivalent of parent.children.indexOf(child)
        var index = Array.prototype.indexOf.call(parent!.children, child);

        let landingCostA = parent!.parentNode!.children[1]!.children[index].cloneNode(true);
        let landingCostB = parent!.parentNode!.children[2]!.children[index].cloneNode(true);
        fixedDiv.append(landingCostHeading || "");
        fixedDiv.append(landingCostA || "");
        fixedDiv.append(landingCostB || "");
        modalBox.prepend(fixedDiv);
      }
    }
  }
}, 1000);


            //classList.add('comparemodalbox');
           let headings:JSX.Element[] = [];

           for(let heading of props.headings) {
            let headingItems:JSX.Element[] = [];

            
             for (var j = 0, col; col = heading[0].cells[j]; j++) {
               //iterate through columns
               //columns would be accessed using the "col" variable assigned in the for loop
               let output = col.innerText;
               if(col.innerText.length<1) output = '-----';
               headingItems.push(<li data-label={col.innerText}>{output}</li>);
             } 
             headings.push(<ul className={styles.CompareHeadings}>{headingItems}</ul>);
             
           }


          

           return ( <>{headings}</>)
            })()
          }


          {
            (() => {


            




              let lists:JSX.Element[] = [];

              for(let chain of props.compare) {

                let listItems:JSX.Element[] = [];

                for (var j = 0, col; col = chain.cells[j]; j++) {
                  //iterate through columns
                  //columns would be accessed using the "col" variable assigned in the for loop

                  let output = col.innerText;
                  if(col.innerText.length<1) output = '-----';
                  let doCompare;

                  const isNumeric = (string:string) => /^[+-]?\d+(\.\d+)?$/.test(string)

                  if(isNumeric(output))
                    {
                      doCompare = output;
                    }
                  if(output !='DELETE')
                    {
                      listItems.push(<li data-label={col.innerText} data-docompare={doCompare}>{output}</li>);
                    }
                } 
                lists.push(<ul className={[styles.CompareList,' compareliststatic'].join(' ')}>{listItems}</ul>);
                
              }


             

              return ( <>{lists}</>)
            })()
          }
          {
            (() =>{
              setTimeout(() => {
                
            
              let comparelists = document.getElementsByClassName('compareliststatic');
              console.log(comparelists);
              let list1 = comparelists[0];
              let list2 = comparelists[1];

              list1.querySelectorAll('li').forEach(function(item,index){
                
                if(typeof item.dataset.docompare !='undefined')
                  {
                    let comp1 = item.dataset.docompare;
                    let item2 = list2?.querySelectorAll('li');
                    let comp2 = item2[index]?.dataset?.docompare ?? 0;

                    if(comp1 > comp2)
                      {
                        //item.classList.add(styles.comparehigher);
                        item.innerHTML = '<span class="'+styles.comparehigher+'">'+comp1+'</span>';
                      }
                    else if(comp1 < comp2)
                      {
                        list2.querySelectorAll('li')[index].innerHTML = '<span class="'+styles.comparehigher+'">'+comp1+'</span>';

                      }
                  }

                }, 1000);
              });

            })()
          }
          </div>
        <div className={[styles.cancel].join(' ')} onClick={closeModal}>Close</div>
      </div>
  );
}

export type TabData = Array<{
  name: string;
  form: Array<FormFieldData|FormGroupData>
}>;